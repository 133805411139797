html {
  /* height: 100%; */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.digitalExperienceWrapper {
  user-select: none;
  width: 100%;
  height: 100%;
}

/* To remove */
.newsflash-wrapper {
  display: block !important;
}

.colorTitle,
.links a,
.text {
  font-family: 'Styrene Medium', Helvetica, Arial, Sans-serif;
}

@media (max-width: 480px) {
}
@media (max-width: 850px) {
}

@font-face {
  font-family: 'Styrene Regular';
  src: url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Regular.eot);
  src: url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Regular.eot?#iefix)
      format('embedded-opentype'),
    url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Regular.woff) format('woff'),
    url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Styrene Medium';
  src: url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Medium.eot);
  src: url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Medium.eot?#iefix)
      format('embedded-opentype'),
    url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Medium.woff) format('woff'),
    url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Styrene Bold';
  src: url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Bold.eot);
  src: url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Bold.eot?#iefix)
      format('embedded-opentype'),
    url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Bold.woff) format('woff'),
    url(//common.static.burberry.com/fonts/v5/BBY-Styrene/BurberryStyreneB-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
